import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import "react-image-gallery/styles/css/image-gallery.css"
import styles from '../styles';
import Starsteaders from '../components/Starsteaders'
import Footer from '../components/Footer'

const useStyles = makeStyles(theme => { return {
  ...styles(theme)
}})

function StarsteadersPage() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root} style={{backgroundColor: "#051320"}}>
        <Starsteaders />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default StarsteadersPage