import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite'
import styles from '../styles'
import ImageGallery from 'react-image-gallery'
import Icon from '@material-ui/core/Icon'
import GetAppIcon from '@material-ui/icons/GetApp'
import discordIcon from '../images/Discord-Logo-Black.svg' 
import trelloIcon from '../images/trello-mark-white.svg'
import {Container, Typography} from '@material-ui/core'
import Spacer from '../components/Spacer'
import BannerImage from '../components/BannerImage'
import starsteadersLogo from '../images/Starsteaders Logo.png'
import WhiteLinkButton from '../components/WhiteLinkButton'
import WhiteButton from '../components/WhiteButton'
import Hidden from '@material-ui/core/Hidden'
import OutboundLink from '../components/OutboundLink'

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  starsteadersSplash: {
    padding: "100px 25px 75px 25px",
    [theme.breakpoints.down('xs')]: {
      padding: "50px 25px 25px 25px",
    },
  },
}))

let Starsteaders = observer((props) => {
  const classes = useStyles()

  const images = [
    {
      original: 'https://files.jellygon.com/www/screenshots/run_rock.jpg',
      thumbnail: 'https://files.jellygon.com/www/screenshots/run_rock_640.jpg',
    },
    {
      original: 'https://files.jellygon.com/www/screenshots/run_dirt_trees.jpg',
      thumbnail: 'https://files.jellygon.com/www/screenshots/run_dirt_trees_640.jpg',
    },
    {
      original: 'https://files.jellygon.com/www/screenshots/drop_pod_takeoff.jpg',
      thumbnail: 'https://files.jellygon.com/www/screenshots/drop_pod_takeoff_640.jpg',
    },
    {
      original: 'https://files.jellygon.com/www/screenshots/s2logo_starfield_particles.png',
      thumbnail: 'https://files.jellygon.com/www/screenshots/s2logo_starfield_particles_thumb.png',
    },
  ]

  // const [isMac, setIsMac] = useState();
  // useEffect(() => {
  //   setIsMac(window.navigator.platform.toUpperCase().indexOf('MAC') >= 0)
  // })
  let winDownload = process.env.GATSBY_USER_BRANCH === 'master' 
      ? 'https://files.jellygon.com/starsteaders/installers/win/Starsteaders_0.1.6.9.exe'
      : 'https://files.jellygon.com/starsteaders/installers/win_dev/StarsteadersDev_0.1.6.6.exe';
  // let macDownload = null;

  let prepend = 
    <Container maxWidth="md" style={{marginTop: "0px", marginBottom: "50px", padding: "0"}}>
      <Container maxWidth="md" style={{display: "flex", alignItems: "center", flexDirection: "column", padding: "0"}}>
        <Typography variant="h6" style={{fontWeight: "initial"}} align="center">
          The galaxy is full of planets, many of them unexplored.  Those who leave their homes to seek adventure 
          in distant space are known as...
        </Typography>
      </Container>
    </Container>

  return (
    <React.Fragment>
      <div style={{backgroundColor: "#051320", color: "#fff"}}>
        <BannerImage image={starsteadersLogo} imageStyles={{maxWidth: "1000px"}} 
            className={classes.starsteadersSplash} prepend={prepend}>
          <Container maxWidth="md" style={{marginTop: "40px", marginBottom: "20px"}}>
            <Container maxWidth="md" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Hidden xsDown>
                <Spacer amount="50px" />
                <WhiteButton id="download" mx="auto" href={winDownload} style={{marginBottom: "10px"}} gaCategory="File Download">
                  Get for Windows
                  <GetAppIcon className={classes.rightIcon} />
                </WhiteButton>
                <div>Want to play on Mac?  <OutboundLink href="https://discord.gg/U7x8Yum">Let me know!</OutboundLink></div>
              </Hidden>
            </Container>
          </Container>
        </BannerImage>
        <Container maxWidth="md" style={{padding: 0}}>
          <ImageGallery items={images} renderPlayPauseButton={() => {}} />
        </Container>
        <Spacer amount="50px" />
        <Container maxWidth="md" style={{marginTop: "40px", marginBottom: "20px", padding: "0"}}>
          <Container maxWidth="md" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <Typography variant="h6" style={{fontWeight: "initial"}} align="center">
              Explore, farm and build.  Alone or with friends.  On planets or in your spaceship.  
              Set in a persistent online universe.  You can play for free while things get started, 
              but in the future only sponsors at a certain level will be able to play!
            </Typography>
          </Container>
        </Container>
        <Spacer amount="50px" />
        <Container maxWidth="sm" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          {/* <WhiteLinkButton href="/whatisthis" size="large">Help me make Starsteaders!</WhiteLinkButton>
          <Spacer amount="40px" /> */}
          <WhiteButton href="https://discord.gg/U7x8Yum" mx="auto" target="_blank" rel="noopener noreferrer">
            Join our Discord
            <Icon classes={{root: classes.rightIcon}}>
              <img alt="Discord Icon" className={classes.imageIcon} src={discordIcon} style={{width: "100%"}} />
            </Icon>
          </WhiteButton>
          <Spacer amount="40px" />
          <Typography variant="body1">Trello is a tool that helps me keep track of all the tasks I have to do (there's
          a lot).  In the spirit of community, I'm making the Starsteaders Trello public so you can see what I'm 
          working on from day to day.</Typography>
          <Spacer amount="40px" />
          <WhiteButton href="https://trello.com/b/RdclPC7y/starsteaders" mx="auto" target="_blank" rel="noopener noreferrer">
            Check out our Trello
            <Icon classes={{root: classes.rightIcon}}>
              <img alt="Trello Icon" className={classes.imageIcon} src={trelloIcon} style={{width: "70%"}}/>
            </Icon>
          </WhiteButton>
        </Container>
        <Spacer amount="100px" />
      </div>
    </React.Fragment>
  )
})

export default Starsteaders